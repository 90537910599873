// Buttons
//
// Custom buttons for the docs.

.btn-bd-primary {
  font-weight: 600;
  color: $bd-purple-bright;
  border-color: $bd-purple-bright;

  &:hover,
  &:active {
    color: $white;
    background-color: $bd-purple-bright;
    border-color: $bd-purple-bright;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($bd-purple-bright, .25);
  }
}

.btn-bd-download {
  font-weight: 600;
  color: $bd-download;
  border-color: $bd-download;

  &:hover,
  &:active {
    color: $bd-dark;
    background-color: $bd-download;
    border-color: $bd-download;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba($bd-download, .25);
  }
}
