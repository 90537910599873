// clipboard.js
//
// JS-based `Copy` buttons for code snippets.

.bd-clipboard {
  position: relative;
  display: none;
  float: right;

  + .highlight {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.btn-clipboard {
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 10;
  display: block;
  padding: .25rem .5rem;
  @include font-size(75%);
  color: #818a91;
  background-color: transparent;
  border: 0;
  @include border-radius();

  &:hover {
    color: $white;
    background-color: #027de7;
  }
}
