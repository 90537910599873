//
// Docs color palette classes
//

@each $color, $value in $colors {
  .swatch-#{$color} {
    color: color-yiq($value);
    background-color: #{$value};
  }
}

@each $color, $value in $grays {
  .swatch-#{$color} {
    color: color-yiq($value);
    background-color: #{$value};
  }
}
